import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import LineChart from './LineChart';
import TabCont from './WellsAndRigs/TabCont';
import {
    handleForecast,
    handleForecastingData,
    handleFullScrnAnalytics,
    handleAnalyticsTabIndex,
    handleSelectedForecastPoints,
    handleShowAndHideTable,
} from '../store/actions/wells-rigs-action';
import {
    GAS, OIL, ANALYTICS_MONTHLY_TAB, capitalize, LINE_CHART_XAXIS_FILTERS,

} from '../../utils/helper';
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject, } from '../models/redux-models';
import ForecastFilter from './WellsAndRigs/ForecastFilter';
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { Tooltip } from 'react-tooltip'
import DownloadProductionLink from '../aoi/DownloadProductionLink';
import AggregationDropdown from "../aoi/AggregationDropdown";

function AnalyticsFullScreen() {
    const fullScrnAnalytics = useAppSelector(state => state.wellsAndRigs.fullScrnAnalytics);
    const fullScrnAnalyticsType = useAppSelector(state => state.wellsAndRigs.fullScrnAnalyticsType);
    const tabIndex = useAppSelector(state => state.wellsAndRigs.tabIndex);
    const selectedWellRigOrPermitList = useAppSelector(state => state.wellsAndRigs.selectedWellRigOrPermitList);
    const showTable = useAppSelector(state => state.wellsAndRigs.showTable);
    const openForeCast = useAppSelector(state => state.wellsAndRigs.openForeCast);
    const analyticsProDataType = useAppSelector(state => state.wellsAndRigs.analyticsData.type);
    const xAxisFilter = useAppSelector(state => state.wellsAndRigs.analyticsData.xAxisFilter);
    const normalized = useAppSelector(state => state.wellsAndRigs.analyticsData.normalized);
    const apiListObj = useAppSelector(state => state.wellsAndRigs.analyticsData.apiListObj);
    const graphDataLoading = useAppSelector(state => state.wellsAndRigs.analyticsData.graphDataLoading);

    const dispatch = useAppDispatch();
    const [state, setState] = useState<{
        // dowData: DowData[], 
        showDropDownOption: boolean,
    }>({
        // dowData: [],
        showDropDownOption: false,
    })

    const {
        // dowData,
        showDropDownOption } = state;

    const typeCurve = xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? true : false;

    const disableNormalize = useMemo(() => {
        // If one of the selected wells has a 0 lateral length then we should disable 'Normalize'
        if (selectedWellRigOrPermitList.map((well: WellsAndPermitsObject | ProductionDataObj | RigsDataObj) => !isInstanceOfWellsAndPermitsObject(well) ? 1 : well.lateral_length ? well.lateral_length : 0).includes(0)) {
            return true
        }
        return false
    }, [selectedWellRigOrPermitList])

    const notAllHorizontalWellsSelected = useMemo(() => {
        // If one of the selected wells has a 0 lateral length then we should disable 'Normalize'
        let notAllHor = false
        for (let well of selectedWellRigOrPermitList) {
            if (isInstanceOfWellsAndPermitsObject(well) && well.drill_type !== "Horizontal") {
                notAllHor = true
                break
            }
        }

        return notAllHor;
    }, [selectedWellRigOrPermitList])


    if (!fullScrnAnalytics) {
        return <></>
    }

    return (
        // forcastingOverlay
        <div
            className={`${fullScrnAnalytics ?
                `analyticFullgraph analyticFullGraphOverlap ${!showTable ? "analyticsFullScreenGraph" : ""} ${openForeCast ? `forcastingOverlay ${apiListObj.length === 1 ? "forecastingSingleDraw" : "typeCurveMultiDraw"}` : ""}`
                : "d-none"}`}
            onClick={() => {
                showDropDownOption && setState((prev) => ({ ...prev, showDropDownOption: false }))
            }}>
            <div className="card-box">
                <div className="card-box-header">
                    <div className="labelheading">{
                        openForeCast && !typeCurve ?
                            `${apiListObj[0]['well_name']} - ${tabIndex === 1 ? (apiListObj as (RigsDataObj)[])[0]['api'] : (apiListObj as (WellsAndPermitsObject | ProductionDataObj)[])[0]["well_api"]} ` : ''}
                        {capitalize(fullScrnAnalyticsType) === "Oil" ? "Liquids" : capitalize(fullScrnAnalyticsType)} Production {!typeCurve ? " Forecast" : "Type Curve"}</div>
                    <div className="action-btn">
                        <Link to="" onClick={() => {
                            dispatch(handleForecastingData({ data: null }));
                            dispatch(
                                handleSelectedForecastPoints({
                                    data: null,
                                    doNotConCat: true,
                                })
                            );
                            dispatch(handleForecast(false));
                            dispatch(handleAnalyticsTabIndex(0))


                            dispatch(handleShowAndHideTable(!showTable))
                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))


                        }}>
                            <img
                                src="images/full.svg"
                                alt=""
                            />
                        </Link>
                        <DownloadProductionLink iconSize="small" />
                        <Link to="" onClick={() => {
                            dispatch(handleForecastingData({ data: null }));
                            dispatch(
                                handleSelectedForecastPoints({
                                    data: null,
                                    doNotConCat: true,
                                })
                            );
                            dispatch(handleForecast(false));
                            dispatch(handleShowAndHideTable(!showTable))
                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))

                        }}>
                            <img src="images/cross.svg" alt="" />
                        </Link>
                    </div>
                </div>
                <div className="box-inside">
                    <div className="normalize">
                        <AggregationDropdown />
                    </div>
                    <div className="garph scrollSection">
                        {(fullScrnAnalyticsType === OIL || fullScrnAnalyticsType === GAS) ? analyticsProDataType === ANALYTICS_MONTHLY_TAB && graphDataLoading ? <div className="norecord">Loading...</div> : <LineChart usedFor={fullScrnAnalyticsType} yLabel={`${fullScrnAnalyticsType === OIL ? `${normalized ? "bbl/1000ft" : "bbl"}` : `${normalized ? "mcf/1000ft" : "mcf"}`}`} id={fullScrnAnalyticsType} useDateXAxis={xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"]} /> : <></>}

                        {/* {fullScrnAnalyticsType === PRODUCTION_DONUT_CHART ? <DonutChart data={dataList} /> : <></>} */}
                        {/* {fullScrnAnalyticsType === OPERATOR_PIE_CHART ? <PieChart data={operatorPieChartDataList} /> : <></>} */}
                    </div>
                    <TabCont onlyCheckedData={true} />
                </div>
            </div >
            <ForecastFilter />
            <Tooltip id="analytics-production-tooltip" />
            {disableNormalize || notAllHorizontalWellsSelected ? <Tooltip id="disable-normalize-tooltip" /> : ''}

        </div >
    )
}

export default AnalyticsFullScreen