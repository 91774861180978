import React, { useEffect, useRef, useState, useMemo } from "react";
import * as d3 from "d3";
import "./lineChart.css";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
  GAS,
  OIL,
  convertToDisplayFormatShortCurrency,
  sixtyColors,
  ANALYTICS_CUM_TAB,
} from "../../utils/helper";
import { handleSelectedForecastPoints } from "../store/actions/wells-rigs-action";
import moment from "moment";
import { getLineChartData } from "../../Helper/commonFunction";

const LineChart = ({ usedFor, yLabel, id, useDateXAxis }) => {
  const mainChartRef = useRef(null);
  const legendRef = useRef(null);
  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  const resizeObserver = new ResizeObserver((entries) => {
    // When the parent div's size changes, update the state with the new width
    for (let entry of entries) {
      if (entry.target === parentRef.current) {
        setParentWidth(entry.contentRect.width);
      }
    }
  });

  const fullScreenAnalyticsGraphTitle = useAppSelector(
    (state) => state.wellsAndRigs.fullScreenAnalyticsGraphTitle
  );
  const selectedRowId = useAppSelector(
    (state) => state.wellsAndRigs.selectedRowId
  );
  const tabIndex = useAppSelector((state) => state.wellsAndRigs.tabIndex);
  const fullScrnAnalytics = useAppSelector(
    (state) => state.wellsAndRigs.fullScrnAnalytics
  );
  const openForeCast = useAppSelector(
    (state) => state.wellsAndRigs.openForeCast
  );
  const selectedWellRigOrPermitList = useAppSelector(
    (state) => state.wellsAndRigs.selectedWellRigOrPermitList
  );
  const prodLiq = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.prodLiq
  );
  const prodGas = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.prodGas
  );
  const gasAggDate = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.gasAggDate
  );
  const gasAggMonth = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.gasAggMonth
  );
  const liqAggDate = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.liqAggDate
  );
  const liqAggMonth = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.liqAggMonth
  );
  const normalized = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.normalized
  );
  const type = useAppSelector((state) => state.wellsAndRigs.analyticsData.type);
  const action = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.action
  );
  const apiList = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.apiList
  );
  const apiListObj = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.apiListObj
  );
  const selectedForecastPoints = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.selectedForecastPoints
  );
  const xAxisFilter = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.xAxisFilter
  );
  const dataList = useAppSelector(
    (state) => state.wellsAndRigs.analyticsData.forecastingData.dataList
  );

  const dispatch = useAppDispatch();
  const [noData, setNoData] = useState(false);
  const convertDateMonthsToString = (d) => {
    const date = d.production_date;
    if (useDateXAxis && typeof date === typeof new Date()) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
    return `Month ${d.production_month}`;
  };

  useEffect(() => {
    noData && setNoData(false);
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(prodLiq),
    // eslint-disable-next-line
    JSON.stringify(prodGas),
    // eslint-disable-next-line
    JSON.stringify(gasAggDate),
    // eslint-disable-next-line
    JSON.stringify(gasAggMonth),
    // eslint-disable-next-line
    JSON.stringify(liqAggDate),
    // eslint-disable-next-line
    JSON.stringify(liqAggMonth),
  ]);

  useEffect(() => {
    if (!mainChartRef.current || !parentRef.current) return;
    d3.select(mainChartRef.current).selectAll("*").remove();
    d3.select(legendRef.current).selectAll("*").remove();
    let selectedRowWellName = fullScreenAnalyticsGraphTitle;
    let matchRow = (apiListObj || [])?.filter(
      (item) => item.id === selectedRowId
    );
    fullScrnAnalytics &&
      !openForeCast &&
      matchRow.length &&
      (selectedRowWellName = matchRow[0]["well_name"]);

    /** we first try and see if there is already aggregation data for the same
     * list of wells, cum/not-cum, normalized/not-normalized, and action.  if so,
     * then we just use that
     */
    let usedForMaybeCum = "";
    let actionMaybeNormalized = action || "none";
    if (type === ANALYTICS_CUM_TAB) {
      usedForMaybeCum = `${usedFor}-cum`;
    } else {
      usedForMaybeCum = usedFor;
    }
    if (normalized) {
      actionMaybeNormalized = `${action}-normalized`;
    }
    let data = getLineChartData(
      dataList,
      normalized,
      type,
      prodLiq,
      prodGas,
      gasAggDate,
      gasAggMonth,
      liqAggDate,
      liqAggMonth,
      usedFor,
      OIL,
      GAS,
      selectedWellRigOrPermitList,
      fullScrnAnalytics,
      useDateXAxis,
      action,
      apiList,
      selectedForecastPoints
    );
    if (!data || data.length === 0 || data.every(d => !d.values?.length)) {
      setNoData(true);
      return;
    }
    const parentWidth = mainChartRef.current.clientWidth;
    const margin = { top: 50, right: 20, bottom: 30, left: 50 };
    let width = parentWidth - margin.left - margin.right;
    const paddingOffset = fullScrnAnalytics ? 20 : 10;
    width = width - paddingOffset;
    const parentHeight = parentRef.current.clientHeight;
    const height = parentHeight - margin.top - margin.bottom;
    const height2 = 50;

    // const color = d3.scaleOrdinal(d3.schemeCategory10);
    const color = d3.scaleOrdinal(sixtyColors);

    const parseDate = d3.timeParse("%Y-%m-%d");

    const x = useDateXAxis
      ? d3.scaleTime().range([paddingOffset, width])
      : d3.scaleLinear().range([paddingOffset, width]);
    const x2 = useDateXAxis
      ? d3.scaleTime().range([paddingOffset, width])
      : d3.scaleLinear().range([paddingOffset, width]);
    const y = d3.scaleLinear().range([height, 0]);
    const y2 = d3.scaleLinear().range([height2, 0]);

    // Define the minimum spacing you want between ticks
    const tickSpacing = 50; // Adjust this value as needed

    // Calculate the number of ticks based on width and tickSpacing
    let tickCount = Math.max(1, Math.floor(width / tickSpacing));
    if (tickCount > 7) {
      tickCount = 7;
    }

    // Use d3.utcFormat for custom tick format
    const xAxis = useDateXAxis
      ? d3.axisBottom(x).ticks(tickCount).tickFormat(d3.utcFormat("%b-%y"))
      : d3.axisBottom(x);
    // const x2Axis = d3.axisBottom(x2).tickFormat(d3.utcFormat("%b-%y"));

    const line = d3
      .line()
      .x((d) => x(useDateXAxis ? d.production_date : d.production_month))
      .y((d) => y(d.production_qty))
      .defined((d) => !isNaN(d.production_qty))
      .curve(d3.curveLinear);

    const mainSvg = d3
      .select(mainChartRef.current)
      .append("svg")
      .attr("width", parentWidth)
      .attr("height", parentHeight);

    // Inside the useEffect hook, after other SVG elements are created
    if (data.length) {
      // Append the tooltip line
      mainSvg
        .append("line")
        .attr("class", "tooltip-line")
        .style("stroke", "white")
        .style("stroke-dasharray", "5,5")
        .style("opacity", 0)
        .attr("y2", height + margin.bottom); // Extend line to bottom

      // Handle mouse movement to show tooltip line and text
      mainSvg.on("mousemove", handleMouseMove);
    }

    function handleMouseMove(event) {
      const [xPos, yPos] = d3.pointer(event);

      // Append the tooltip to the SVG
      mainSvg.select("#tooltip").remove(); // Remove any existing tooltip

      // Update position of tooltip line
      mainSvg
        .select(".tooltip-line")
        .attr("x1", xPos)
        .attr("y1", 0)
        .attr("x2", xPos)
        .attr("y2", height)
        .style("opacity", 1);

      // Prepare tooltip content
      let tooltipContent = "";

      // Find the corresponding xValue using the xScale
      const xValue = x.invert(xPos);

      // Iterate over each line's data points
      sources.forEach((source, _i) => {
        const bisectDate = d3.bisector((d) =>
          useDateXAxis ? d.production_date : d.production_month
        ).left;
        const index = bisectDate(source.values, xValue, 1);
        const d0 = index > 0 ? source.values[index - 1] : null; // Check if index is valid
        const d1 = index < source.values.length ? source.values[index] : null; // Check if index is within range

        if (d0 && d1) {
          const d =
            xValue - (useDateXAxis ? d0.production_date : d0.production_month) >
              (useDateXAxis ? d1.production_date : d1.production_month) - xValue
              ? d1
              : d0;

          if (d) {
            const xPosValue = x(
              useDateXAxis ? d.production_date : d.production_month
            ); // x position of the data point
            // Check if the xPos is close to the tooltip's xPos
            if (Math.abs(xPosValue - xPos) < 2) {
              const wellAPI = apiListObj
                .filter((well) => well.well_name === source.name)
                .map((well) => well.well_api);
              // Only show details for the line where the tooltip line intersects
              const spanColor = `${(openForeCast && _i === 1) ||
                (openForeCast && !dataList && _i === 0)
                ? "#2585c6"
                : openForeCast
                  ? "grey"
                  : color(source.name)
                }`;

              tooltipContent += `<div><span style="color: ${spanColor};">
              ${wellAPI.length > 0
                  ? wellAPI[0]
                  : source.name.length > 16
                    ? source.name.slice(0, 16) + "..."
                    : source.name
                }</span><br><span style="color: ${spanColor};">${convertDateMonthsToString(
                  d
                )} - ${convertToDisplayFormatShortCurrency(d.production_qty)}
              </span></div>`;
            }
          }
        }
      });

      // Update content and position of tooltip text

      if (tooltipContent) {
        const lineHeight = 16; // Assuming 16px line height, adjust according to your styling
        const numLines = tooltipContent.split("</div>").length * 2;
        const tooltipPadding = 5; // Padding on each side of the tooltip content
        const tooltipWidth = 150; // Default tooltip width
        const tooltipHeight = numLines * lineHeight + tooltipPadding * 2; // Add padding on both sides

        // Calculate the container's width
        const containerWidth = mainSvg.node().getBoundingClientRect().width;

        // Calculate the tooltip's position
        let tooltipX = xPos + 10; // Default to right side
        if (xPos + tooltipWidth + 20 > containerWidth) {
          tooltipX = xPos - tooltipWidth - 10; // Move to the left side
        }

        mainSvg
          .append("foreignObject")
          .attr("id", "tooltip")
          .attr("x", tooltipX)
          .attr("y", yPos - tooltipHeight)
          .attr("width", tooltipWidth)
          .attr("height", tooltipHeight)
          .html(
            `<div style="font-size: 12px; background-color: rgba(0, 0, 0, 0.7); padding: ${tooltipPadding}px; border-radius: 5px; position: relative; z-index: 9999;">${tooltipContent}</div>`
          );
      }
    }

    // Hide tooltip line and text on mouseout
    mainSvg.on("mouseout", () => {
      mainSvg.select(".tooltip-line").style("opacity", 0);
      // tooltipText.style("opacity", 0);
      mainSvg.select("#tooltip").remove();
    });

    mainSvg
      .append("defs")
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("x", 0)
      .attr("y", -margin.top)
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom);

    color.domain(
      Object.keys(data[0].values[0] || {}).filter((key) => {
        return key !== "production_date";
      })
    );
    let newData = [];
    data.forEach((countryData) => {
      const newCountryData = { ...countryData };
      newCountryData.values = countryData.values.map((d) => ({
        ...d,
        production_date: parseDate(d.production_date),
        production_qty: +d.production_qty,
      }));
      newData.push(newCountryData);
    });
    data = newData;

    const sources = data.map((countryData) => {
      return {
        name: countryData.name,
        values: countryData.values,
      };
    });

    if (fullScrnAnalytics && !openForeCast) {
      // Define dataWithoutForecast within the useEffect hook
      const dataWithoutForecast = data.filter(
        (d, i) => i < data.length - (dataList || []).length
      );
      // Create legend
      const legendSvg = d3
        .select(legendRef.current)
        .append("svg")
        .attr("width", 200) // Adjust the width as needed
        .attr("height", 100); // Adjust the height as needed

      const legend = legendSvg
        .selectAll(".legend-item")
        .data(dataWithoutForecast)
        .enter()
        .append("g")
        .attr("class", "legend-item")
        .attr("transform", (d, i) => `translate(10, ${i * 20})`); // Adjust spacing

      legend
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", (d) => color(d.name));

      legend
        .append("text")
        .attr("x", 20)
        .attr("y", 5)
        .attr("dy", "0.35em")
        .text((d) =>
          action.toLowerCase() === "none"
            ? d.api
            : d.name.length > 20
              ? d.name.slice(0, 17) + "..."
              : d.name
        )
        .style("font-size", "12px")
        .style("fill", "white");
    }

    x.domain([
      d3.min(data, (d) =>
        d3.min(d.values, (v) =>
          useDateXAxis ? v.production_date : v.production_month
        )
      ),
      d3.max(data, (d) =>
        d3.max(d.values, (v) =>
          useDateXAxis ? v.production_date : v.production_month
        )
      ),
    ]);
    y.domain([
      d3.min(sources, (c) => {
        return d3.min(c.values, (v) => v.production_qty) * 0.9; // Add 10% padding below
      }),
      d3.max(sources, (c) => {
        return d3.max(c.values, (v) => v.production_qty) * 1.1; // Keep 20% padding above
      }),
    ]);

    x2.domain(x.domain());
    y2.domain(y.domain());

    const focuslineGroups = mainSvg
      .selectAll(".line-group")
      .data(sources)
      .enter()
      .append("g")
      .attr("class", "line-group");

    focuslineGroups.each(function (d, i) {
      const currentGroup = d3.select(this);
      const isForeCast = i >= sources.length - (dataList || []).length;

      // Create line segments
      for (let j = 0; j < d.values.length - 1; j++) {
        // Determine the color of the line segment
        let segmentColor;
        let strokeDash = "none";

        if (isForeCast) {
          segmentColor = "#ffffff"; // White for forecast
          strokeDash = "5,5";    // Dashed for forecast
        } else if (d.values[j].highlight && d.values[j + 1].highlight) {
          // Both current and next points are highlighted
          segmentColor = openForeCast ? "#2585c6" : color(d.name);
        } else {
          // At least one of the points is not highlighted
          segmentColor = "grey";
        }

        currentGroup
          .append("line")
          .attr("x1", line.x()(d.values[j], j))
          .attr("y1", line.y()(d.values[j], j))
          .attr("x2", line.x()(d.values[j + 1], j + 1))
          .attr("y2", line.y()(d.values[j + 1], j + 1))
          .style("stroke", segmentColor)
          .style("stroke-width", d.name === selectedRowWellName ? 3 : 1)
          .style("stroke-dasharray", strokeDash)
          .attr("clip-path", "url(#clip)")
          .on("mouseover", isForeCast ? null : handleLineMouseOver)
          .on("mouseout", isForeCast ? null : handleLineMouseOut)
          .on("contextmenu", isForeCast ? null : handleLineClick);
      }

      // Only add points for non-forecast lines
      if (!isForeCast) {
        currentGroup
          .selectAll("circle")
          .data(d.values)
          .enter()
          .append("circle")
          .attr("class", "circle")
          .attr("cx", (d) => x(useDateXAxis ? d.production_date : d.production_month))
          .attr("cy", (d) => y(d.production_qty))
          .attr("r", 1.5)
          .style("opacity", 1)
          .style("fill", function (d) {
            return d.highlight
              ? openForeCast
                ? "#2585c6"
                : color(d3.select(this.parentNode).datum().name)
              : "grey";
          })
          .attr("clip-path", "url(#clip)")
          .on("mouseover", handleMouseOver)
          .on("mouseout", handleMouseOut)
          .on("contextmenu", handleLineClick);
      }
    });

    if (!openForeCast && fullScrnAnalytics) {
      mainSvg
        .append("text")
        .attr("id", `tooltip-${selectedRowId}`)
        .attr("x", width / 2)
        .attr("y", 30)
        .attr("text-anchor", "middle")
        .attr("font-size", "16px")
        .style("fill", color(selectedRowWellName))
        .style("stroke", 3)
        .text(selectedRowWellName);
    }

    mainSvg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).ticks(tickCount));

    let isSelecting = false; // Variable to track if selection is in progress
    let xStart, yStart, selectionRect;

    if (openForeCast && (!dataList || !dataList.length)) {
      // Store original mousemove handler
      const originalMouseMove = mainSvg.on("mousemove");

      // Add event listener for mouse down to start selection
      mainSvg.on("mousedown", function (event) {
        if (event.button !== 0) return; // Only handle left mouse button
        event.preventDefault();

        // Get initial coordinates
        const [xStart, yStart] = d3.pointer(event);

        // Create selection rectangle
        const selectionRect = mainSvg
          .append("rect")
          .attr("class", "selection-rect")
          .attr("x", xStart)
          .attr("y", yStart)
          .attr("width", 0)
          .attr("height", 0)
          .style("fill", "rgba(0, 0, 0, 0.1)")
          .style("stroke", "#fff")
          .style("stroke-width", 1)
          .style("pointer-events", "none");

        // Change cursor style
        mainSvg.style("cursor", "crosshair");

        // Temporarily disable the tooltip during selection
        mainSvg.on("mousemove", function (moveEvent) {
          moveEvent.preventDefault();
          const [xCurrent, yCurrent] = d3.pointer(moveEvent);

          // Update selection rectangle
          const x = Math.min(xStart, xCurrent);
          const y = Math.min(yStart, yCurrent);
          const width = Math.abs(xCurrent - xStart);
          const height = Math.abs(yCurrent - yStart);

          selectionRect
            .attr("x", x)
            .attr("y", y)
            .attr("width", width)
            .attr("height", height);
        });

        // Add mouseup event listener
        mainSvg.on("mouseup", function (upEvent) {
          if (upEvent.button !== 0) return; // Only handle left mouse button
          upEvent.preventDefault();

          const [xEnd, yEnd] = d3.pointer(upEvent);

          // Ignore if it's just a click (no drag)
          if (Math.abs(xEnd - xStart) < 2 && Math.abs(yEnd - yStart) < 2) {
            selectionRect.remove();
            mainSvg.on("mousemove", originalMouseMove);
            mainSvg.on("mouseup", null);
            return;
          }

          // Get final selection coordinates
          const xMin = Math.min(xStart, xEnd);
          const xMax = Math.max(xStart, xEnd);
          const yMin = Math.min(yStart, yEnd);
          const yMax = Math.max(yStart, yEnd);

          // Convert screen coordinates to data coordinates
          const yMinData = y.invert(yMax); // Note: y-axis is inverted
          const yMaxData = y.invert(yMin);

          // Select points within rectangle
          const selectedPoints = [];
          mainSvg.selectAll(".line-group").each(function (lineData) {
            lineData.values.forEach(function (d) {
              const cx = x(useDateXAxis ? d.production_date : d.production_month);
              const cy = y(d.production_qty);
              const selected =
                cx >= xMin &&
                cx <= xMax &&
                d.production_qty >= yMinData &&
                d.production_qty <= yMaxData;

              selectedPoints.push({
                x: cx,
                y: cy,
                production_date: d.production_date,
                production_qty: d.production_qty,
                line: lineData.name,
                selected: selected,
                producing_month: d.production_month,
              });
            });
          });

          // Process selected points using the handleSelectedPoints function
          handleSelectedPoints(selectedPoints);

          // Clean up
          selectionRect.remove();
          mainSvg.style("cursor", "default");
          mainSvg.on("mousemove", originalMouseMove); // Restore original mousemove handler
          mainSvg.on("mouseup", null);
        });

        // Add mouseleave event to handle if user drags outside
        mainSvg.on("mouseleave", function () {
          selectionRect.remove();
          mainSvg.style("cursor", "default");
          mainSvg.on("mousemove", originalMouseMove);
          mainSvg.on("mouseup", null);
          mainSvg.on("mouseleave", null);
        });
      });

      // Function to handle the selected points
      function handleSelectedPoints(selectedPoints) {
        // Check if any points are selected using the more efficient .some() method
        if (!selectedPoints.some(point => point.selected)) {
          return;
        }

        const selectedPointsWithIndex = selectedPoints.map((row, index) => ({
          ...row,
          index: index,
        }));

        dispatch(
          handleSelectedForecastPoints({
            data: selectedPointsWithIndex
              .filter((_item) => _item.selected)
              .map((d) => {
                let tempApi = apiListObj.filter((obj) =>
                  JSON.stringify(obj).includes(d.line)
                );
                return {
                  ...(apiList.length === 1 && {
                    api: tempApi.length
                      ? tabIndex === 1
                        ? tempApi[0]["api"]
                        : tempApi[0]["well_api"]
                      : "",
                  }),
                  production_date: moment(d.production_date).format("YYYY-MM-DD"),
                  production_quantity: d.production_qty,
                  producing_month: d.producing_month,
                  index: d.index,
                };
              }),
          })
        );
      }
    }

    // mainSvg.append("g").attr("class", "y axis").call(d3.axisLeft(y).ticks(10));
    mainSvg
      .append("g")
      .attr("class", "y axis")
      .call(
        d3
          .axisLeft(y)
          .ticks(4)
          .tickFormat((d) => convertToDisplayFormatShortCurrency(d))
      )
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -50) // Adjust the position based on your preference
      .attr("x", -height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("text-anchor", "start")
      .style("fill", "#fff")
      .text(yLabel);

    // Add horizontal grid lines with red color
    mainSvg
      .append("g")
      .attr("class", "grid")
      .call(
        d3.axisLeft(y).ticks(4).tickSize(-width).tickFormat("").tickSizeOuter(0) // Hide the outer tick line
      )
      .selectAll("line")
      .attr("stroke", "#696B6C")
      .attr("stroke-width", ".3px")
    // .attr("y2", height + margin.bottom); // Extend grid lines

    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height2],
      ])
      .on("brush end", brushed);

    const zoom = d3
      .zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([
        [0, -margin.top],
        [width, height + margin.bottom], // Extended bottom boundary
      ])
      .extent([
        [0, -margin.top],
        [width, height + margin.bottom], // Extended bottom boundary
      ])
      .filter(event => {
        // Only allow wheel events (zoom), block all others
        return event.type === 'wheel';
      })
      .on("zoom", zoomed);

    mainSvg.call(zoom);

    // Function to handle click on the line
    function handleLineClick(event, d) {
      event.preventDefault(); // Prevent default context menu behavior
      // const [xPos, yPos] = d3.pointer(event);
      // Access the data properties as needed
      
    }

    function zoomed(event) {
      if (event.sourceEvent && event.sourceEvent.type === "brush") return;

      const transform = event.transform;
      let newXDomain = transform.rescaleX(x2).domain();
      let newYDomain = transform.rescaleY(y2).domain();

      if (!useDateXAxis) {
        // Restrict x-axis movement to 0
        newXDomain = [Math.max(0, newXDomain[0]), Math.max(0, newXDomain[1])];
      }

      if (newYDomain[0] < 0) {
        // Restrict y-axis movement to 0
        newYDomain = [0, newYDomain[1] - newYDomain[0]];
      }

      if (useDateXAxis) {
        // Ensure month granularity
        const startDate = new Date(newXDomain[0]);
        const endDate = new Date(newXDomain[1]);
        newXDomain = [startDate, endDate];
      }

      x.domain(newXDomain);
      y.domain(newYDomain);

      // Update line segments instead of lines
      mainSvg.selectAll(".line-group").each(function (d) {
        const currentGroup = d3.select(this);

        currentGroup.selectAll("line").each(function (datum, index) {
          if (index < d.values.length - 1) {
            // Check to avoid accessing out of bounds
            d3.select(this)
              .attr(
                "x1",
                x(
                  useDateXAxis
                    ? d.values[index].production_date
                    : d.values[index].production_month
                )
              )
              .attr("y1", y(d.values[index].production_qty))
              .attr(
                "x2",
                x(
                  useDateXAxis
                    ? d.values[index + 1].production_date
                    : d.values[index + 1].production_month
                )
              )
              .attr("y2", y(d.values[index + 1].production_qty));
          }
        });
      });

      // Update any other elements as needed, such as circles, axes...
      mainSvg
        .selectAll("circle")
        .attr("cx", (d) =>
          x(useDateXAxis ? d.production_date : d.production_month)
        )
        .attr("cy", (d) => y(d.production_qty))
        .attr("clip-path", "url(#clip)");

      mainSvg.select(".x.axis").call(xAxis);
      mainSvg.select(".y.axis").call(
        d3
          .axisLeft(y)
          .ticks(4)
          .tickFormat((d) => convertToDisplayFormatShortCurrency(d))
      );

      if (event.transform.k !== 1) {
        // Zooming is in progress, set isSelecting to false
        isSelecting = false;
        // Remove selection rectangle if present
        if (selectionRect) selectionRect.remove();
        // Revert cursor style to default
        mainSvg.style("cursor", "default");
      }
    }

    function brushed(event) {
      if (event.sourceEvent && event.sourceEvent.type === "dblclick") {
        resetZoom();
        return;
      }

      if (event.selection) {
        const s = event.selection;
        x.domain([x2.invert(s[0]), x2.invert(s[1])]);

        mainSvg.selectAll(".line").attr("d", (d) => line(d.values));
        mainSvg.select(".x.axis").call(xAxis);
        mainSvg.select(".y.axis").call(
          d3
            .axisLeft(y)
            .ticks(4)
            .tickFormat((d) => convertToDisplayFormatShortCurrency(d))
        );
        // Update circle positions
        mainSvg
          .selectAll("circle")
          .attr("cx", (d) =>
            x(useDateXAxis ? d.production_date : d.production_month)
          )
          .attr("cy", (d) => y(d.production_qty));

        if (event.sourceEvent) {
          const [xPos] = d3.pointer(event.sourceEvent);
          const isMouseInBrushingArea = xPos >= 0 && xPos <= width;

          if (!isMouseInBrushingArea) {
            mainSvg.select(".brush").call(brush.move, null);
          }
        }
      } else if (event.transform) {
        const transform = event.transform;
        x.domain(transform.rescaleX(x2).domain());

        mainSvg.selectAll(".line").attr("d", (d) => line(d.values));
        mainSvg.select(".x.axis").call(d3.axisBottom(x).ticks(tickCount));
        mainSvg.select(".y.axis").call(
          d3
            .axisLeft(y)
            .ticks(4)
            .tickFormat((d) => convertToDisplayFormatShortCurrency(d))
        );
        // Update circle positions
        mainSvg
          .selectAll("circle")
          .attr("cx", (d) =>
            x(useDateXAxis ? d.production_date : d.production_month)
          )
          .attr("cy", (d) => y(d.production_qty));
      }
    }

    function handleMouseOver(event, d) {
      const [xPos, yPos] = d3.pointer(event);

      // Prepare the tooltip text
      let tooltipText = `Date: ${d3.timeFormat("%Y-%m-%d")(d.production_date)}`;
      tooltipText += `<br/>`;
      tooltipText += `Production: ${convertToDisplayFormatShortCurrency(
        d.production_qty
      )}`;
      tooltipText += `<br/>`;

      // Add line names and corresponding production_qty values
      sources.forEach((countryData) => {
        const lineValue = countryData.values.find(
          (value) => value.production_date === d.production_date
        );
        if (lineValue) {
          tooltipText += `${countryData.name}`;
          tooltipText += `<br/>`;
        }
      });

      // Append the tooltip to the SVG
      mainSvg
        .append("foreignObject")
        .attr("id", "tooltip")
        .attr("x", xPos + 10)
        .attr("y", yPos + 30)
        .attr("width", 120)
        .attr("height", 80)
        .html(
          `<div style="font-size: 12px; background-color: rgba(0, 0, 0, 0.7); color: #fff; padding: 5px; border-radius: 5px;">${tooltipText}</div>`
        );
    }

    function handleMouseOut() {
      // d3.select(this).style("opacity", 0);
      d3.select(this).attr("r", 1.5).style("opacity", 1);
      mainSvg.select("#tooltip").remove();
    }

    function handleLineMouseOver(event, d) {
      const countryName = d.name;

      if (selectedRowWellName === countryName && !openForeCast) {
        return;
      }

      focuslineGroups
        .filter((data) => data.name === countryName)
        .select(".line")
        .style("stroke-width", 3);

      // Get the mouse coordinates
      const [xPos, yPos] = d3.pointer(event);
      mainSvg
        .append("text")
        .attr("id", "tooltip")
        .attr("x", xPos + 30)
        .attr("y", yPos - 30)
        .attr("text-anchor", "middle")
        .attr("font-size", "16px")
        .style("fill", color(d.name))
        .style("stroke", 3)
        .text(countryName);
    }

    function handleLineMouseOut(event, d) {
      const countryName = d.name;
      if (selectedRowWellName === countryName && !openForeCast) {
        return;
      }

      focuslineGroups
        .filter((data) => data.name === countryName)
        .select(".line")
        .style("stroke-width", 1);

      //  Remove the tooltip when mouseout
      mainSvg.select("#tooltip").remove();
    }

    function resetZoom() {
      x.domain(x2.domain());
      y.domain(y2.domain());

      mainSvg.selectAll(".line").attr("d", (d) => line(d.values));

      mainSvg.selectAll(".line-group .line").style("stroke-width", 1);

      // mainSvg.selectAll("circle").style("opacity", 0);
      // Update circle positions
      mainSvg
        .selectAll("circle")
        .attr("cx", (d) =>
          x(useDateXAxis ? d.production_date : d.production_month)
        )
        .attr("cy", (d) => y(d.production_qty))
        .attr("r", 1.5)
        .style("opacity", 1);

      mainSvg.select(".x.axis").call(d3.axisBottom(x).ticks(tickCount));
      mainSvg.select(".y.axis").call(
        d3
          .axisLeft(y)
          .ticks(4)
          .tickFormat((d) => convertToDisplayFormatShortCurrency(d))
      );
    }

    // Start observing the parent div for size changes
    resizeObserver.observe(parentRef.current);

    return () => {
      mainSvg.selectAll("*").remove();
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(prodLiq),
    // eslint-disable-next-line
    JSON.stringify(prodGas),
    // eslint-disable-next-line
    JSON.stringify(gasAggDate),
    // eslint-disable-next-line
    JSON.stringify(gasAggMonth),
    // eslint-disable-next-line
    JSON.stringify(liqAggDate),
    // eslint-disable-next-line
    JSON.stringify(liqAggMonth),
    // eslint-disable-next-line
    usedFor,
    // eslint-disable-next-line
    useDateXAxis,
    //eslint-disable-next-line
    JSON.stringify(dataList),
    //eslint-disable-next-line
    JSON.stringify(selectedForecastPoints),
    //eslint-disable-next-line
    selectedRowId,
    parentWidth,
    normalized,
    fullScreenAnalyticsGraphTitle,
    action,
    xAxisFilter,
    type,
    mainChartRef.current,
    parentRef.current,
  ]);
  if (noData) {
    return (<div className="full-center-column">
      <img src="images/barrel-crossed-out.svg" alt="Pumpjack" className="margin-bottom-point-3" />
      <span className="text-white">Production Data Unavailable</span>
    </div>)
  }
  return (
    // no-data-found
    <div
      className={`lineChart ${noData ? "no-data-found" : ""}`}
      ref={parentRef}
      style={{
        minHeight: "calc(50vh - 6rem)",
        ...(openForeCast && { paddingRight: 0 }),
      }}
    >
      <div
        className={`legend ${fullScrnAnalytics && !openForeCast ? "" : "d-none"
          }`}
        ref={legendRef}
      ></div>
      <div
        className="mainChart"
        id={id}
        ref={mainChartRef}
        style={{ height: "100%" }}
      ></div>

      {noData ? (
        <div className="no-data-found">
          <figure>
            <img
              src={
                usedFor === OIL
                  ? "images/oil-nodata.svg"
                  : "images/gas-nodata.svg"
              }
              alt=""
            />
          </figure>
          <h2>No {usedFor === OIL ? "Oil" : "Gas"} Production Record</h2>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LineChart;
